import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
    return (
        <section id='experience'>
           <h5>What Are My Skills</h5>
           <h2>Experience</h2>

           <div className="container experience__container">
               <div className="experience__frontend">
                <h3>Frontend Development</h3>
                <div className="experience__content">
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                            <h4>HTML</h4>
                            <small className="text-light ">Intermediate</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>CSS</h4>
                        <small className="text-light ">Intermediate</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>JavaScript</h4>
                        <small className="text-light ">Intermediate</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Bootstrap</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Typescript</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>React</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Angular</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                </div>
               </div>
               {/* End of Frontend */}
               <div className="experience__backend">
               <h3>Backend Development</h3>
                <div className="experience__content">
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>NodeJS</h4>
                        <small className="text-light ">Intermediate</small>
                        </div>   
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>MongoDB</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>ExpressJS</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>MySQL</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Python</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Java</h4>
                        <small className="text-light ">Intermediate</small>
                        </div>
                    </article>
                </div>
               </div>
               <div className="experience__ux">
               <h3>User Experience Development</h3>
                <div className="experience__content">
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>Figma</h4>
                        <small className="text-light ">Intermediate</small>
                        </div>
                    </article>
                    <article className="experience__details">
                        <BsPatchCheckFill className="experience__details-icon"/>
                        <div>
                        <h4>User Research</h4>
                        <small className="text-light ">Basic</small>
                        </div>
                    </article>
                </div>
               </div>
           </div>
        </section>
    )
}

export default Experience
