import React from 'react'
import './About.css'
import Me from '../../assets/Jarumi.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
    return (
        <section id='about'>
            <h5>Get to Know</h5>
            <h2>About Me</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={Me} alt="About Image" />
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__section">
                        <h3>Full Stack UI/UX Developer | Poet | Community Enthusiast </h3>
                        <p>
                            Greetings! I'm Jarumi, a seasoned Full Stack UI/UX Developer with a passion for crafting innovative digital solutions.
                            With three years of professional experience, I have honed my skills working for prestigious companies in both the financial 
                            technology sector and the defense contracting industry.  I am a versatile individual who thrives on challenges, embraces 
                            creativity, and seeks to make a meaningful impact in both the digital and real-world spheres. If you're looking for a 
                            dedicated professional with a holistic approach to problem-solving, I'm excited to bring my skills and passion to your team.
                            Let's innovate and create together!
                        </p>
                    </div>
                    <div className="about__cards">
                        
                        <article className='about__card'>
                            <FaAward className='about__icon'/>
                            <h5>Experience</h5>
                            <small>3 Years Working Experience</small>
                        </article>

                        <article className='about__card'>
                            <FiUsers className='about__icon'/>
                            <h5>Clients</h5>
                            <small>From all over the US</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon'/>
                            <h5>Projects</h5>
                            <small>3 Year Working Experience</small>
                        </article>
                </div>

                {/* <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Totam in ipsam laborum aliquid ducimus, unde beatae quasi odio sit et corporis tempore dolorem sint sequi ab. Dolores quisquam sint velit!
                </p> */}
                
                {/* <a href="#contact" className='btn btn-primary about-btn'>Let's Talk</a>     */}
                </div>
            </div>
        </section>
    )
}

export default About
