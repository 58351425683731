import React from 'react'
import './Portfolio.css'
import IMG1 from '../../assets/portfolio/portfolio1.jpg'
import IMG2 from '../../assets/portfolio/portfolio2.jpg'
import IMG3 from '../../assets/portfolio/portfolio3.jpg'
import IMG4 from '../../assets/portfolio/portfolio4.jpg'
import IMG5 from '../../assets/portfolio/portfolio5.jpg'
import IMG6 from '../../assets/portfolio/portfolio6.jpg'

const data = [
    // {
    //   id: 1,
    //   image: IMG1,
    //   title: 'Crypto Currency Dashboard & Financial Visualization',
    //   github: 'https://github.com/Jarumi-Bonner/',
    //   demo: 'https://github.com/Jarumi-Bonner/'
    // },
    // {
    //   id: 2,
    //   image: IMG2,
    //   title: 'Charts templates & infographics in Figma',
    //   github: 'https://github.com/Jarumi-Bonner/',
    //   demo: 'https://github.com/Jarumi-Bonner/'
    // },
    {
      id: 3,
      image: IMG3,
      title: 'Figma interactive UI Design: Coffee Culture and Change',
      github: 'https://github.com/Jarumi-Bonner/coffeecultureandchange',
      demo: 'https://www.figma.com/proto/8fhfwh7JSZFXR7sSPzHRjc/Coffee-Culture-%26-Change?page-id=0%3A1&node-id=118-1313&viewport=355%2C5209%2C0.66&scaling=scale-down&starting-point-node-id=118%3A1313'
    },
    // {
    //   id: 4,
    //   image: IMG4,
    //   title: 'Maintaining tasks and tracking progress',
    //   github: 'https://github.com/Jarumi-Bonner/',
    //   demo: 'https://github.com/Jarumi-Bonner/'
    // },
    // {
    //   id: 5,
    //   image: IMG5,
    //   title: 'Charts templates & infographics in Figma',
    //   github: 'https://github.com/Jarumi-Bonner/',
    //   demo: 'https://github.com/Jarumi-Bonner/'
    // },
    // {
    //   id: 6,
    //   image: IMG6,
    //   title: 'Charts templates & infographics in Figma',
    //   github: 'https://github.com/Jarumi-Bonner/',
    //   demo: 'https://github.com/Jarumi-Bonner/'
    // }
  ]
  


const Portfolio = () => {
    return (
       <section id="portfolio">
           <h5>My Recent Work</h5>
           <h2>Portfolio</h2>

           <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className='btn' target='_blank'>Github</a>
                <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
            )
          })
        }
      </div>
       </section>
    )
}

export default Portfolio
